<template>
  <div>
    <!--     申请         -->
    <template v-if="one.nodeType==0">
      <div class="flow-detail_col">
        <div class="">
          <span>{{`申请人       ${one.userList[0].name}`}}</span>
          <span class="tip_dept">{{`(${one.userList[0].deptName})`}}</span>
        </div>
        <div>{{one.dealTime}}</div>
      </div>
    </template>
    <!--     抄送         -->
    <template v-if="one.nodeType==2&&one.userList&&one.userList.length">
      <div class="flow-detail_col">
        <div class="">
          {{`抄送`}}
          <span v-for="(co,index) in one.userList" class="lineHeight20">
            {{co.name}}
            <span class="tip_dept">{{`(${co.deptName})`}}</span>
            <span v-if="index !== one.userList.length-1">,</span>
          </span>
        </div>
        <div>{{one.dealTime}}</div>
      </div>
    </template>
    <!--    审批          -->
    <template v-if="one.nodeType==1&&(one.userList.length)">
      <template v-for="user in one.userList">
        <div class="flow-detail_col"  v-if="['0','1'].includes(user.dealType)">
          <div class="flow-detail_col_child">
            <div>
              {{`${user.name}`}}
              <span class="tip_dept">{{`(${user.deptName})`}}</span>
            </div>
            <div style="border-left: 1px solid #CCCCCC;">{{`${['已同意','已拒绝'][user.dealType]}      ${user.dealReson?'（'+(user.dealReson)+'）':''}`}}</div>
          </div>
          <div>{{user.dealTime}}</div>
        </div>
      </template>
      <template v-if="!one.userList.length">
        <div class="flow-detail_col">
          <div>{{one.dealTime}}</div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "print-flow",
  props:['one']
}
</script>

<style scoped lang="scss">
@mixin col(){
  border-right: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  margin: 0px;
  padding: 10px;
}
.flow-detail_col{
  display: flex;
  & > div{
    flex: 1;
    @include col;
  };
  .flow-detail_col_child{
    flex:1;
    display: flex;
    box-sizing:border-box;
    & > div{
      flex: 1;
      margin: -10px;
      padding: 10px 20px 10px 10px;
    };
  }
}
.tip_dept{
  color: #767676;
  font-size: 12px;
}
</style>