<template>
  <print-table v-if="form.id" :form="form" :historyRecord="historyRecord"
               :title="title">
    <template v-slot:form>
        <div class="info_item">
          <div>提交人</div>
          <div>{{form.createBySysUserName.username||''}}</div>
        </div>
        <div class="info_item">
          <div>提交时间</div>
          <div>{{form.submitTime}}</div>
        </div>
        <div class="info_item">
          <div>店铺</div>
          <div>{{form.shopName||''}}</div>
        </div>
        <div class="info_item">
          <div>店铺号</div>
          <div>{{form.shopNo||''}}</div>
        </div>
        <div class="info_item">
          <div>业态</div>
          <div>{{form.businessName||''}}</div>
        </div>
        <div class="info_item">
          <div class="float-left">备注</div>
          <div class="detail_item__remark">{{form.description}}</div>
        </div>
    </template>
  </print-table>
</template>

<script>
import PrintTable from "@/components/print-table";

export default {
  name: "decoration-print",
  components: {PrintTable},
  data(){
    return {
      id:0,
      form:{},
      historyRecord:[],
      title:''
    }
  },
  created(){
    this.id=this.$route.query.id;
    this.getDetail();
  },
  methods:{
    getDetail() {
      this.$api.check.getDecDetail({id: this.id}).then(res => {
        this.form = res.data;
        this.title = ['','施工图申请','店铺效果图申请','平面图申请','围挡效果图申请'][this.form.typeId]
        if(res.data.hasHistory){
          this.getHistoryActivity(res.data.id)
        }
      })
    },
    getHistoryActivity(id){
      this.$api.check.getHisRecord({id:id}).then(response=>{
        if(response.code===200){
          this.historyRecord = response.data.list;
        }
      })
    }
  }

}
</script>

<style scoped lang="scss">


</style>